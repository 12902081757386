import React, { FC, useEffect } from "react";
import styled from "styled-components";

import {
  Footer,
  InfoSection,
  LastSection,
  MainSection,
} from "src/components/organisms/";

export const IndexTemplate: FC = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <IndexContainer>
      <MainSection />
      <InfoSection />
      <LastSection />
      <Footer />
    </IndexContainer>
  );
};

const IndexContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;
