import React, { FC, useEffect } from "react";
import {
  InfoText,
  InfoTitle,
  InfoSecondTitle,
  InfoSecondText,
  InfoThirdTitle,
  InfoThirdText,
  InfoFourthTitle,
  InfoFourthText,
  SectionButton,
} from "src/components/atoms";
import "animate.css/animate.min.css";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { AnimationOnScroll } from "react-animation-on-scroll";
import styled from "styled-components";
import { defaultColor } from "src/globalStyle";
import { FadeInWhenVisible } from "./api";

const lists = [
  {
    title: "신청자격",
    text: "<strong>4대보험</strong>가입 근로자",
  },
  {
    title: "소득조건",
    text: "<strong>월소득</strong><strong>100만원</strong>이상",
  },
  {
    title: "신용등급",
    text: "<strong>1 ~ 7등급</strong>이내",
  },
  {
    title: "연체이력",
    text: "<strong>최근 3개월</strong>이내 X",
  },
];

export const InfoSection: FC = () => {
  return (
    <MainSectionBlock>
      <FirstSection>
        <InnerSection>
          <FadeInWhenVisible>
            <CircleItem>
              <InfoText>최대한도</InfoText>
              <InfoTitle>2억원</InfoTitle>
            </CircleItem>
            <CircleItem>
              <InfoText>이자율</InfoText>
              <InfoTitle>4.3%~</InfoTitle>
            </CircleItem>
            <CircleItem>
              <InfoText>상환기간</InfoText>
              <InfoTitle>최대10년</InfoTitle>
            </CircleItem>
          </FadeInWhenVisible>
        </InnerSection>
      </FirstSection>
      <SecondSection>
        <InnerSection>
          <FadeInWhenVisible
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <div>
              <InfoSecondTitle>고금리 사용중인</InfoSecondTitle>
              <InfoSecondTitle>근로자, 직장인 대상</InfoSecondTitle>
              <InfoSecondTitle>비대면 대환대출 지원</InfoSecondTitle>
              <br />
              <InfoSecondText>기대출 과다자도</InfoSecondText>
              <InfoSecondText>연 금리 4.3%~</InfoSecondText>
              <InfoSecondText>최대 10년 상환으로 넉넉하게</InfoSecondText>
            </div>
            <div>
              {/* <p /> */}
              <img alt="" src="/assets/barchart.png" width="800" />
            </div>
          </FadeInWhenVisible>
        </InnerSection>
      </SecondSection>
      <ThirdSection>
        <InnerSection>
          <FadeInWhenVisible
            animateOnce
            delay={700}
            animateIn="animate__fadeInUp"
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <InfoThirdTitle>근로소득 활동 시</InfoThirdTitle>
              <InfoThirdTitle>은행방문 없이 무서류</InfoThirdTitle>
              <br />
              <InfoThirdText>
                직장인이라면 누구나 최저금리 무료비교,
              </InfoThirdText>
              <InfoThirdText>
                1금융권 대환대출 부터 모든금융사 분석
              </InfoThirdText>
              <InfoThirdText>
                확정한도 확인! (한도,이자율 유선안내)
              </InfoThirdText>
            </div>

            <div>
              <img alt="" src="/assets/phone2.png" width="540" />
            </div>
          </FadeInWhenVisible>
        </InnerSection>
      </ThirdSection>
      <FourthSection>
        <InnerSection>
          <FadeInWhenVisible
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
            }}
          >
            <div>
              <InfoFourthTitle>저금리 대출 희망자</InfoFourthTitle>
              <InfoFourthTitle>고금리 기대출 이용</InfoFourthTitle>
              <InfoFourthTitle>근로자 대상 채무통합</InfoFourthTitle>
              <p />
              <InfoFourthText>개인회생,회복, 파산 이력 신청불가</InfoFourthText>
              <InfoFourthText>신용불량자 신청불가</InfoFourthText>
              <SectionButton
                onClick={() => {
                  window.location.href = "#last_section";
                }}
              >
                채무통합 신청하기
              </SectionButton>
            </div>
          </FadeInWhenVisible>
          <div>
            <CardBox>
              {lists.map((item) => (
                <FadeInWhenVisible>
                  <CardItem>
                    <CardHeader>{item.title}</CardHeader>
                    <CardBody
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    />
                  </CardItem>
                </FadeInWhenVisible>
              ))}
            </CardBox>
          </div>
        </InnerSection>
      </FourthSection>
      <FifthSection>
        <InnerSection>
          <FadeInWhenVisible
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              color: "white",
              // margin: "20px 0",
            }}
          >
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              4대보험 가입 직장인 우대
            </div>
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              재직기간 6개월 이상 직장인
            </div>
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              신용카드 사용자
            </div>
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              우량직군 (대기업, 공무원 등) 직장인 우대
            </div>
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              아파트 및 자동차 (자산) 소유자 우대
            </div>
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              부채 : 담보를 제외한 연봉 대비 250% 미만
            </div>
            <div>
              <img alt="" src="/assets/check.svg" width="20" />
              제외 : 최근 3개월 이내 연체자
            </div>
          </FadeInWhenVisible>
        </InnerSection>
      </FifthSection>
    </MainSectionBlock>
  );
};

const MainSectionBlock = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FirstSection = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;

  div#motion {
    display: flex;
    gap: 160px;
    justify-content: center;
  }

  @media (max-width: 1150px) {
    div#motion {
      gap: 90px;
    }
  }

  @media (max-width: 835px) {
    height: auto;
    padding: 30px 0;

    div#motion {
      width: 100%;
      align-items: center;
      gap: 9px;

      div {
        width: auto;
        min-width: auto;
        flex: 1;

        border: none;
        background-color: transparent;
      }
    }
  }

  @media (max-width: 410px) {
    div#motion {
      width: 100%;
      align-items: center;
      gap: 9px;

      div {
        width: auto;
        min-width: auto;
        flex: 1;
        border: none;
        background-color: transparent;
      }
    }
  }
`;

const SecondSection = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: white;
  padding: 60px 0;
  position: relative;

  & > div {
    align-items: start;
  }

  #motion {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 835px) {
      flex-direction: column;
    }
  }

  img {
    width: 580px;

    @media (max-width: 1150px) {
      width: 335px;
    }

    @media (max-width: 835px) {
      width: 420px;
      padding-top: 50px;
    }
    @media (max-width: 410px) {
      width: 260px;
      padding-top: 20px;
    }
  }
`;

const ThirdSection = styled.div`
  width: 100%;
  height: 660px;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: #1b2857;
  padding: 60px 0 0;
  position: relative;

  & > div {
    align-items: flex-end;
  }

  & > div > div > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 1;

    & > div {
      width: 50%;

      display: flex;
      justify-content: center;

      p {
        margin: 10px;
      }

      &:first-child {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }

  @media (max-width: 1150px) {
    height: 560px;
    & > div {
      display: flex;
      flex-direction: column-reverse;
      justify-content: end;
      align-items: center;

      & img {
        top: 37px;

        @media (max-width: 835px) {
          width: 400px;
          padding-top: 20px;
        }
        @media (max-width: 410px) {
          width: 280px;
          padding-top: 20px;
        }
      }
    }
  }

  @media (max-width: 835px) {
    height: 450px;
  }
  @media (max-width: 410px) {
    height: 375px;
  }
`;

const FourthSection = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: white;
  padding: 100px 0;
  position: relative;

  #motion {
    display: flex;
  }

  & > div {
    align-items: flex-start;
    flex: 1 1;
    margin-top: 60px;

    & > div {
      p {
        margin: 40px;
      }

      &:first-child {
        position: relative;
      }
    }
  }

  @media (max-width: 1150px) {
    & > div {
      flex-direction: column;
      align-items: center;
      margin-top: 0%;

      & > div:first-child {
        width: 80% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        p {
          margin: 10px;
        }

        button {
          margin-top: 30px;
          margin-bottom: 60px;
        }
      }

      & > div:not(:first-child) {
        width: 80%;

        & > div {
          flex-direction: row !important;
          flex-wrap: wrap;
          gap: 20px;

          & > #motion {
            width: 45%;
            & > div {
              flex-direction: column !important;
            }
          }
        }

        div {
          width: 100%;
          flex-direction: column;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

const FifthSection = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: ${defaultColor.primary_dark};
  padding: 100px 0;
  position: relative;

  div#motion {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: white;
    font-size: 20px;
    font-weight: 500;

    @media (max-width: 835px) {
      font-size: 16px;
    }

    @media (max-width: 410px) {
      font-size: 11px;
    }

    img {
      position: relative;
      top: 3px;
      margin-right: 12px;
    }
  }
`;

const InnerSection = styled.div`
  width: 80%;
  max-width: 1080px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircleItem = styled.div`
  width: 200px;
  height: 200px;
  min-width: 200px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #4596d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const CardItem = styled.div`
  width: 200px;
  height: 220px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px 5px #0000001c;
  border-radius: 20px;

  @media (max-width: 410px) {
    height: 150px;
  }
`;

const CardHeader = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4f4f4f;
  color: white;
  font-size: 20px;

  font-family: "NEXONLv1Gothic";
  font-weight: bold;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  @media (max-width: 410px) {
    font-size: 16px;
  }
`;

const CardBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;

  @media (max-width: 410px) {
    font-size: 16px;
    line-height: 20px;
  }

  strong {
    font-size: 30px;

    @media (max-width: 410px) {
      font-size: 20px;
      line-height: 20px;
    }
  }
`;
