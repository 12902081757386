import React, { FC } from "react";
import {
  MainTitle,
  MainSubTitle,
  MainText,
  MainButton,
} from "src/components/atoms";
import { AnimationOnScroll } from "react-animation-on-scroll";
import styled from "styled-components";
import { Header } from "../header";
import { defaultColor } from "src/globalStyle";

export const MainSection: FC = () => {
  return (
    <MainSectionBlock>
      <Header />
      <MainImage />
      <AnimationOnScroll
        animateOnce
        delay={300}
        animateIn="animate__fadeInUp"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "105px",
          justifyContent: "center",
        }}
      >
        <SectionInner>
          <MainText>
            <strong>4대보험 가입 근로자 대상</strong>
          </MainText>
          <MainText>채무통합 대환대출 지원</MainText>
        </SectionInner>
        <SectionInner>
          <p>*6개월 이상 4대보험에 가입된 직장인만 신청가능한 지원상품입니다</p>
          <p>*현재 회생, 회복, 파산중이신 고객님은 지원대상에서 제외됩니다</p>
          <MainButton
            onClick={() => {
              window.location.href = "#last_section";
            }}
          >
            채무통합 신청하기
          </MainButton>
        </SectionInner>
      </AnimationOnScroll>
    </MainSectionBlock>
  );
};

const MainSectionBlock = styled.div`
  width: 80%;
  max-width: 1080px;
  height: 100vh;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 160px;

  @media (max-width: 835px) {
    width: 90%;
  }

  & > img {
    width: 600px;
    position: absolute;
    right: 10%;
    bottom: 0;
  }

  & > div:last-child {
    margin-bottom: 150px !important;

    @media (max-width: 835px) {
      justify-content: center !important;
      margin-bottom: 200px !important;
    }

    @media (max-width: 410px) {
      justify-content: center !important;
      margin-bottom: 180px !important;
    }
  }
`;

const MainImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  // background: url("/assets/main_background_image_sm.png") no-repeat center
  //   center/contain;
  background: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    url("/assets/bg.png");
  background-size: cover;
  z-index: -1;
  left: 0;
  right: 0;

  @media (max-width: 1150px) {
    background-position-x: -480px;
  }
  @media (max-width: 835px) {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) -720px,
      url("/assets/bg.png");
    background-size: cover;
    background-position-x: -820px;
  }
  @media (max-width: 410px) {
    background-size: cover;
    background-position-x: -595px;
  }
`;

const SectionInner = styled.div`
  width: 100%;
  max-width: 1080px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  p {
    color: #000000;
    margin: 3px 0;
    font-size: 18px;

    @media (max-width: 835px) {
      font-size: 12px;
      color: white;
    }
  }

  @media (max-width: 835px) {
    align-items: center;
  }
`;
