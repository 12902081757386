import { defaultColor } from "src/globalStyle";
import styled from "styled-components";

export const MainButton = styled.button`
  all: unset;
  width: fit-content;
  color: black;
  background-color: white;
  border-radius: 45px;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 50px;
  margin: 150px auto 0;
  right: 0;
  left: 0;
  position: absolute;
  @media (max-width: 410px) {
    font-size: 14px;
  }
`;

export const SectionButton = styled.button`
  all: unset;
  width: fit-content;
  color: white;
  background-color: ${defaultColor.primary};
  border: 1px solid currentColor;
  border-radius: 20px;
  font-size: 22px;
  font-weight: 500;
  padding: 15px 90px;
  margin-top: 100px;

  @media (max-width: 835px) {
    width: auto;
    padding: 15px 25px;
    font-size: 18px;
  }
`;

export const FormButton = styled.button<{ active?: boolean }>`
  all: unset;
  width: 100%;
  color: black;
  background-color: ${(props) => (props.active ? "#ffe487" : "white")};
  border: 1px solid #9f9f9f;
  border: ${(props) => props.active && "none"};
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 15px 20px;
`;

export const LastButton = styled.button`
  all: unset;
  width: 90%;
  color: white;
  background-color: ${defaultColor.primary};
  border-radius: 45px;
  font-family: "NEXONLv1Gothic";
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  padding: 25px 30px;

  @media (max-width: 835px) {
    font-size: 20px;
    padding: 15px 30px;
  }

  @media (max-width: 410px) {
    font-size: 15px;
    padding: 15px 15px;
    width: 100%;
  }
`;
