import React, { FC, useState } from "react";
import {
  LastSectionTitle,
  FormButton,
  LastButton,
  InputText,
} from "src/components/atoms";
import styled from "styled-components";
import PulseLoader from "react-spinners/PulseLoader";
import { defaultColor } from "src/globalStyle";

export const Footer: FC = () => {
  return (
    <MainSectionBlock id="last_section">
      <SectionInner>
        <p>
          대출금리: 연 ~20%이내 (담보대출에 한해 심사기준에 따라 차등적용)
          연체이율: 연 ~20% 이내 대출중개수수료 없음
          <br /> 중개수수료를 요구하거나 받는것은 불법으로 대출과 관련된 일체
          수수료를 받지 않습니다.
          <br /> 취급수수료 등 기타부대비용 없음 / 상환방법 :
          원리금균등상환방식, 만기일시상환방식
          <br /> 이자 외 추가비용 없음 단, 일부 담보대출상품에 한해 저당설정,
          해지비용 및 조기상환수수료가 발생할수 있습니다.
          <br /> 조기상환조건 : 대출실행일로 부터 1년 이내 상환시 최초 대출금 2%
          적용,
          <br /> 단 이자와 조기상환 수수료의 합산액은 20%를 초과하지 않음.
          <br /> 이 사이트에서 광고되는 상품들의 상환기간은 모두 60일 이상이며
          최장 상환기간 120개월 미만입니다.
          <br /> 대출 총비용 예시는 다음과 같습니다.
          <br /> 1,000,000원을 12개월 동안 이자 20%, 최대 이자율 20%로 대출할시
          총 상환금액은 1,111,614원입니다.
          <br /> (대출 상품에 따라 달라질 수 있습니다)
          <br />
          <br /> 우리안심금융센터는 100% 무료상담입니다.
          <br />
          <strong>Copyright ⓒ 우리안심금융센터. All rights reserved.</strong>
        </p>
      </SectionInner>
    </MainSectionBlock>
  );
};

const MainSectionBlock = styled.div`
  width: 90%;
  height: auto;
  display: flex;
  background-color: ${defaultColor.muted};
  flex-direction: column;
  align-items: center;
  padding: 0 160px;
`;

const SectionInner = styled.div`
  width: 100%;
  max-width: 900px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  p {
    width: 100%;
    margin: 3px 0;
    color: #ebecec;
    font-size: 12px;
    font-weight: 400;
    text-align: left;

    strong {
      color: #b7babd;
      font-weight: 400;
    }
  }
`;
