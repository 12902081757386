import React, { FC, useState, useEffect, useRef } from "react";
import {
  LastSectionTitle,
  FormButton,
  LastButton,
  InputText,
} from "src/components/atoms";
import styled from "styled-components";
import PulseLoader from "react-spinners/PulseLoader";
import Repository from "../../../service/repository";
import TagManager from "react-gtm-module";
import url from "url";
import moment from "moment";
import axios from "axios";
import {
  Typography,
  Button,
  Stack,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
} from "@mui/material";

const repository = new Repository();

const tagManagerDataLayerSubmitData = {
  dataLayer: {
    event: "submit_data",
  },
  dataLayerName: "SubmitData",
};

const tagManagerDataLayerSubmitDataSalary3500 = {
  dataLayer: {
    event: "submit_data_3500",
  },
  dataLayerName: "SubmitData",
};

const tagManagerDataLayerSubmitDataSalary3000Debt2000 = {
  dataLayer: {
    event: "submit_data_3000_debt_2000",
  },
  dataLayerName: "SubmitData",
};

const tagManagerDataLayerSubmitDataSalary4500 = {
  dataLayer: {
    event: "submit_data_4500",
  },
  dataLayerName: "SubmitData",
};

const tagManagerDataLayerSubmitDataHouseOwner = {
  dataLayer: {
    event: "submit_data_house_owner",
  },
  dataLayerName: "SubmitData",
};

const makeDebtOptionTagManagerDataLayerSubmitData = function (
  debtSize: String
) {
  return {
    dataLayer: {
      event: "submit_data_debt_" + debtSize,
    },
    dataLayerName: "SubmitData",
  };
};

const tagManagerInitArgs = {
  gtmId: process.env.REACT_APP_GTM_ID ? process.env.REACT_APP_GTM_ID : "",
  dataLayerName: "SubmitData",
};
TagManager.initialize(tagManagerInitArgs);

const privacyContent = `
개인정보 처리방침
<br><br>

'우리안심금융센터'는 (이하 “회사”라 함)는 신용정보의 이용 및 보호에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법, 개인정보보호법, 대부중개업 등의 등록 및 금융이용자 보호에 관한 법률 등 준수하여야 할 관련 법규상의 개인정보보호규정을 준수하며, 다음과 같이 개인정보취급방침을 정하여 이용자(이하 “고객”)의 권익보호에 최선을 다하고 있습니다. 회사는 개인정보취급방침을 홈페이지에 공개함으로써 이용자들이 언제나 용이하게 보실 수 있도록 조치하고 있으며, 개인정보취급방침은 법령 및 지침에 따라 변경될 수 있으므로 정기적으로 확인하여 주시기 바랍니다. 
<br><br>

[전화권유동의 ]
<br>
본인은 귀사가 상품 이용권유등의 목적으로 다음과 같은 동의합니다. 
<br>
전화,문자메세지,등을 통한 상품 이용권유동의 
<br>
▶기존 계약의 유지.관리를 위한 필수 고지사항은 상기 동의 대상에서 제외됩니다. 
<br>
회사에서 제공하는 관련 부가서비스 안내를  제공 받고자 하며, 추가 서비스 안내를 받지 아니하시는 경우에는 체크박스에 클릭하지 않으시면 됩니다.
<br><br>

[개인정보 수집 항목] 
<br>
회사는 상담, 대출 신청서비스 등을 위해 아래와 같은 개인정보를 수집하고 있습니다. 
<br>
1) 수집항목 : 이름, 생년월일, 핸드폰 번호, 자택(직장) 전화번호, 주소, 이메일, 직업 
<br>
2) 개인정보 수집 및 상담방법 
<br>
- 홈페이지(블로그,카페)등 대표전화를 보고 고객이 내전. 
<br>
- 고객이 이름과 휴대폰 번호를 입력하여 대출상담 신청. 
<br><br>

[개인정보의 수집 및 이용목적] 
<br>
회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 
<br>
1) 대출중개 서비스 및 대출중개 서비스 제공에 따른 수수료정산. 
<br>
<br>
[개인정보의 보유 및 이용기간] 
<br>
이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다. 
<br>
가. 수집, 이용 동의일로부터 (금융)대출 승인 후 2개월, (금융)대출 부결 후 5일 이내까지. 단, 동의철회일 후에는 위에 기재된 이용목적과 관련된 금융사고 조사, 분쟁해결, 민원처리, 법령상 의무이행을 위하여 필요한 범위 내에서만 보유, 이용됩니다. 
<br><br>

[개인정보의 파기 절차 및 방법] 
<br>
회사는 『개인정보의 처리 목적』이 달성되면 다음과 같은 절차 및 방법에 따라 개인정보를 파기합니다.
<br>
가. 개인정보는 『개인정보의 처리 목적』이 달성된 후 별도의 DB로 옮겨져 (종이의 경우 중개목적 달성 후 즉시 파쇄) 『개인정보의 처리 및 보유기간』에 따라 일정 기간 보관 후 파기됩니다. 위 보관된 개인정보 는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다. 
<br>
나. 전자적 파일 형태인 경우 복원이 불가능한 방법으로 영구 삭제하며, 그 외의 기록물, 인쇄물, 서면, 기타 기록 매체인 경우에는 파쇄의 방법으로 파기합니다. 회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 
<br>
파기절차 및 방법은 다음과 같습니다. 
<br>
<br>
- 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 
<br>
- 종이에 출력된 개인정보는 분쇄기로 분쇄합니다. 
<br><br>

[개인정보의 제 3자 제공] 
<br>
회사는 이용자들의 개인정보를 전항 [개인정보의 수집 및 이용목적]에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다. 
<br>
- 이용자가 사전에 동의한 경우 
<br>
- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우 
<br><br><br>


※ 회사의 개인정보 제3자 제공현황 
<br>
개인정보를 제공받는 자 : 
<br>
----------------------------------------------------------------------------- 
<br>
회사가 중개업무를 취급하는 대부금융회사 
<br>
----------------------------------------------------------------------------- 
<br>
[ P2C대부중개 ]
<br>
[ (주)이룸대부중개 ]
<br>
[ (주)프라임케이앤피대부중개 ]
<br>
<br>
----------------------------------------------------------------------------- 
<br>
회사와 중개업무위탁계약을 맺은 대부중개업체 
<br>
----------------------------------------------------------------------------- 
<br>
[ P2C대부중개 ]
<br>
[ (주)이룸대부중개 ]
<br>
[ (주)프라임케이앤피대부중개 ]
<br>
<br>
제공할 신용정보의 내용 : 개인식별정보(성명,주소,생년월일,성별,연락처 등) 
<br>
제공받는자의이용목적 : 대출중개업무를 위한 기본정보제공,전자금융서비스 연동,수수료정산 
<br>
<br>
<br>
※대부중개의 개인정보 취급 위탁 현황 
<br>
대부중개와 중개업무 위탁계약을 맺은 하위 대부중개인 - 개인대출모집 
<br>
<br>
[개인정보에 관한 민원서비스] 
<br>
● 개인정보관리책임자 성명 : 
<br>
● 상호명 : 
<br>
<br>
이용자는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 
<br>
기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다. 
<br>
<br>
1. 개인분쟁조정위원회 (www.1336.or.kr/1336) 
<br>
2. 정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4) 
<br>
3. 대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600) 
<br>
4. 경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330) <br><br>`;

export const LastSection: FC = () => {
  const [yearList, setYearList] = useState<Array<Number>>([]);

  const [name, setName] = useState<string>("");
  const [phone1, setPhone1] = useState<string>("");
  const [phone2, setPhone2] = useState<string>("");
  const [phone3, setPhone3] = useState<string>("");
  const [isJoin, setIsJoin] = useState(true);
  const [salary, setSalary] = useState<Number>(0);
  const [debt, setDebt] = useState<Number>(0);
  const [housingType, setHousingType] = useState(0);
  const [isOverdue, setOverdue] = useState(false);
  const [isAgree, setIsAgree] = useState<Boolean>(false);
  const [infos, setInfos] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [birthYear, setBirthYear] = useState("");
  const now = new Date();

  useEffect(() => {
    const run = async function () {
      setUserInfo(
        (await axios.get("https://us-central1-pj-miami.cloudfunctions.net/api"))
          .data
      );
    };
    const initYearList = () => {
      const tmpList = [];
      const maxYear = now.getFullYear() - 20;
      for (let i = 1900; i <= maxYear; i += 1) {
        tmpList.push(i);
      }
      tmpList.reverse();
      setYearList(tmpList);
    };
    initYearList();
    run();
  }, []);

  const phone3Regex = /^\d{0,3}$/;
  const phone4Regex = /^\d{0,4}$/;

  const nameChangeHandler = (event: any) => {
    setName(event.target.value);
  };
  const phone1ChangeHandler = (event: any) => {
    if (phone3Regex.test(event.target.value)) {
      setPhone1(event.target.value);
    } else {
      alert("숫자만 입력 가능합니다.");
    }
  };
  const phone2ChangeHandler = (event: any) => {
    if (phone4Regex.test(event.target.value)) {
      setPhone2(event.target.value);
    } else {
      alert("숫자만 입력 가능합니다.");
    }
  };
  const phone3ChangeHandler = (event: any) => {
    if (phone4Regex.test(event.target.value)) {
      setPhone3(event.target.value);
    } else {
      alert("숫자만 입력 가능합니다.");
    }
  };
  const salaryChangeHandler = (event: any) => {
    setSalary(event.target.value);
  };
  const debtChangeHandler = (event: any) => {
    setDebt(event.target.value);
  };
  const checkBoxHandler = (event: any) => {
    setIsAgree(event.target.checked);
  };
  const birthYearHandler = (event: any) => {
    setBirthYear(event.target.value);
  };

  /**
   * 개인정보 처리방침 모달
   */
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);

  const privacyModalHandler = (event: any) => {
    setOpenPrivacyModal(true);
  };

  const closePrivacyModal = () => {
    setOpenPrivacyModal(false);
  };
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (openPrivacyModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openPrivacyModal]);

  const validation = () => {
    if (name.length === 0) {
      alert("이름을 입력해주세요.");
      return false;
    }
    if (name.length > 8) {
      alert("이름을 올바르게 입력해주세요");
      return false;
    }
    if (
      !/(010|011|016|017|018|019)/.test(phone1) ||
      !/^\d{3,4}$/.test(phone2) ||
      !/^\d{4}$/.test(phone3)
    ) {
      alert("휴대폰 번호를 올바르게 입력해주세요.");
      return false;
    }
    if (Number(birthYear) < 1971) {
      alert(`1970년 이후 출생자만 신청 가능합니다.`);
      return false;
    }
    if (!isJoin) {
      alert("6개월 이상 4대보험 가입자만 신청 가능합니다.");
      return false;
    }
    if (!isAgree) {
      alert("개인정보 수집이용에 동의해주세요.");
      return false;
    }
    if (salary === 0) {
      alert("연봉을 입력해주세요.");
      return false;
    }
    if (String(salary).length > 6) {
      alert("연봉을 만원 단위로 입력해주세요.");
      return false;
    }
    if (String(debt).length > 6) {
      alert("총 부채 금액을 만원 단위로 입력해주세요.");
      return false;
    }
    if (isOverdue) {
      alert("3개월 내 연체이력이 있으면 신청이 불가능합니다.");
      return false;
    }
    return true;
  };

  const updateInfo = (info: any) => {
    return new Promise((resolve: any, reject) => {
      setInfos((infos) => {
        const updated: any = { ...infos };
        updated[info.id] = info;
        return updated;
      });
      repository.storeInfo(
        phone1.toString() + phone2.toString() + phone3.toString(),
        info
      );
      resolve();
    });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (validation()) {
      const urlQueryStrings = url.parse(window.location.search, true).query;
      const info = {
        name: name,
        phone: phone1.toString() + phone2.toString() + phone3.toString(),
        join: isJoin,
        salary: salary,
        debt: debt,
        isOverdue: isOverdue,
        division: "10MWPM17",
        date: moment(Date.now()).format(),
        campaign: urlQueryStrings ? urlQueryStrings : {},
        housingType: housingType,
        birthYear: birthYear,
        userInfo: userInfo,
      };
      updateInfo(info).then(() => {
        TagManager.dataLayer(tagManagerDataLayerSubmitData);
        if (Number(salary) >= 3500) {
          TagManager.dataLayer(tagManagerDataLayerSubmitDataSalary3500);
        }
        if (Number(salary) >= 4500) {
          TagManager.dataLayer(tagManagerDataLayerSubmitDataSalary4500);
        }
        if (Number(housingType) === 0) {
          TagManager.dataLayer(tagManagerDataLayerSubmitDataHouseOwner);
        }
        if (Number(debt) >= 2000) {
          TagManager.dataLayer(
            makeDebtOptionTagManagerDataLayerSubmitData("2000")
          );
          if (Number(salary) >= 3000) {
            TagManager.dataLayer(
              tagManagerDataLayerSubmitDataSalary3000Debt2000
            );
          }
        }
        if (Number(debt) >= 3000) {
          TagManager.dataLayer(
            makeDebtOptionTagManagerDataLayerSubmitData("3000")
          );
        }

        window.scrollTo({ top: 0, behavior: "smooth" });
        alert(
          "대출 가능 고객님입니다. 평일 09 ~ 18시 사이에 전문 상담사가 연락드리도록 하겠습니다."
        );
        e.target.reset();
      });
    }
  };

  return (
    <MainSectionBlock id="last_section">
      <SectionInner>
        <LastSectionTitle>
          신용등급 영향없는
          <br />
          안심 한도확인
        </LastSectionTitle>
        <form style={{ display: "contents" }} onSubmit={onSubmit}>
          <LastSectionForm>
            <img alt="" src="/assets/padlock.png" width="30" />
            <PulseLoader
              color={"#295bff"}
              css={""}
              size={5}
              speedMultiplier={0.5}
            />

            <p>
              개인정보는 암호화되어 <br />
              안전하게 처리됩니다.
              <br />
              <br /> 신용불량자, 연체자, 무직자는
              <br /> 접수 불가합니다.{" "}
            </p>
            <FormItem>
              <div>이름</div>
              <FormInput
                maxLength={15}
                onChange={nameChangeHandler}
                type="text"
              />
            </FormItem>
            <FormItem>
              <div>휴대폰번호</div>
              <FormRowBox>
                <FormInput
                  max={999}
                  maxLength={3}
                  onChange={phone1ChangeHandler}
                  type="number"
                />
                <FormInput
                  maxLength={4}
                  onChange={phone2ChangeHandler}
                  type="number"
                />
                <FormInput
                  maxLength={4}
                  onChange={phone3ChangeHandler}
                  type="number"
                />
              </FormRowBox>
            </FormItem>
            <FormItem>
              <div>출생년도</div>
              <FormSelect onChange={birthYearHandler} value={birthYear}>
                <option value="default">-- 선택 --</option>
                {yearList.map((value, i) => (
                  <option key={i} value={value.toString()}>
                    {value}
                  </option>
                ))}
              </FormSelect>
            </FormItem>
            <FormItem>
              <div>6개월 이상 4대보험에 가입되어 있으신가요?</div>
              <FormRowBox>
                <FormButton
                  type="button"
                  active={isJoin}
                  onClick={() => {
                    setIsJoin(true);
                  }}
                >
                  가입
                </FormButton>
                <FormButton
                  type="button"
                  active={!isJoin}
                  onClick={() => {
                    setIsJoin(false);
                  }}
                >
                  미가입
                </FormButton>
              </FormRowBox>
            </FormItem>
            <FormItem>
              <div>연봉</div>
              <FormInput
                onChange={salaryChangeHandler}
                maxLength={6}
                type="number"
                pattern="\d*"
              />
              <InputText>만원</InputText>
            </FormItem>
            <FormItem>
              <div>총 부채금액</div>
              <FormInput
                onChange={debtChangeHandler}
                maxLength={7}
                type="number"
                pattern="\d*"
              />
              <InputText>만원</InputText>
            </FormItem>

            <FormItem>
              <div>주거형태</div>
              <FormRowBox>
                <LiquidRowBox>
                  <LiquidRow id="row1">
                    <FormButton
                      type="button"
                      active={housingType === 0}
                      onClick={() => {
                        setHousingType(0);
                      }}
                    >
                      자가
                    </FormButton>
                    <FormButton
                      type="button"
                      active={housingType === 1}
                      onClick={() => {
                        setHousingType(1);
                      }}
                    >
                      전세
                    </FormButton>
                  </LiquidRow>
                  <LiquidRow id="row2">
                    <FormButton
                      type="button"
                      active={housingType === 2}
                      onClick={() => {
                        setHousingType(2);
                      }}
                    >
                      월세
                    </FormButton>
                    <FormButton
                      type="button"
                      active={housingType === 3}
                      onClick={() => {
                        setHousingType(3);
                      }}
                    >
                      없음
                    </FormButton>
                  </LiquidRow>
                </LiquidRowBox>
              </FormRowBox>
            </FormItem>
            <FormItem>
              <div>최근 연체 경험 유무</div>
              <FormRowBox>
                <FormButton
                  type="button"
                  active={isOverdue}
                  onClick={() => {
                    setOverdue(true);
                  }}
                >
                  있음
                </FormButton>
                <FormButton
                  type="button"
                  active={!isOverdue}
                  onClick={() => {
                    setOverdue(false);
                  }}
                >
                  없음
                </FormButton>
              </FormRowBox>
            </FormItem>
          </LastSectionForm>

          <Stack direction="row" margin={2} alignItems="center">
            <Checkbox id="terms" onChange={checkBoxHandler} />
            <Box mr={1}>
              <Typography style={{ fontSize: "1rem" }}>
                개인정보 처리방침 동의{" "}
              </Typography>
            </Box>
            <Typography
              style={{ fontSize: "1rem", color: "#4E5FF2", cursor: "pointer" }}
              onClick={privacyModalHandler}
            >
              [내용보기]
            </Typography>
          </Stack>

          <LastButton type="submit">신용등급 영향없는 안심 한도조회</LastButton>

          <p style={{ color: "#FF0000" }}>
            *4대보험 미가입자, 무직자, 연체자, 신용불량자 신청불가
          </p>
        </form>
      </SectionInner>
      <Dialog
        open={openPrivacyModal}
        onClose={closePrivacyModal}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">개인정보 처리방침</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Typography
              dangerouslySetInnerHTML={{ __html: privacyContent }}
            ></Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePrivacyModal}>닫기</Button>
        </DialogActions>
      </Dialog>
    </MainSectionBlock>
  );
};

const MainSectionBlock = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  background-color: #f0f0f0;
  flex-direction: column;
  align-items: center;
  padding: 0 160px;
`;

const SectionInner = styled.div`
  width: 100%;
  max-width: 700px;
  height: auto;
  display: flex;
  flex-direction: column;

  align-items: center;
  margin: 60px;

  p {
    margin: 3px 0;
    font-size: 18px;
  }

  label {
    font-size: 18px;
    margin-top: 50px;
    padding: 30px 0;
    @media (max-width: 410px) {
      font-size: 14px;
    }
  }

  button + p {
    margin-top: 30px;

    @media (max-width: 835px) {
      font-size: 14px;
    }
    @media (max-width: 410px) {
      font-size: 12px;
      margin-top: 10px;
    }
  }
`;

const LastSectionForm = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 5px 5px #d7d7d7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;

  & > img {
    margin-bottom: 15px;
  }

  & > p {
    text-align: center;
    font-size: 16px;
    margin: 15px;
  }
`;

const FormItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  position: relative;

  & > div:first-child {
    font-size: 16px;
  }
`;

const FormInput = styled.input`
  width: auto;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #9f9f9f;
  padding: 10px 15px;
  font: inherit;
  @media (max-width: 515px) {
    font-size: 15px;
  }
`;

const FormSelect = styled.select`
  border-radius: 5px;
  border: 1px solid #9f9f9f;
  padding: 15px 20px;
  color: black;

  background-color: white;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 21px) calc(24px),
    calc(100% - 16px) calc(24px), calc(100% - 40px) 12px;
  background-size: 5px 5px, 5px 5px, 1px 30px;
  background-repeat: no-repeat;
  @media (max-width: 515px) {
    font-size: 15px;
  }
`;

const FormRowBox = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  input {
    width: 100%;
  }
`;

const LiquidRowBox = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 10px;
  width: 100%;
  @media (max-width: 515px) {
    flex-direction: column;
  }
`;
const LiquidRow = styled.div`
  display: flex;
  width: 50%;
  gap: 10px;

  @media (max-width: 515px) {
    min-width: 100%;
  }
`;
